import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { Wrapper } from "./styled"

const Button = props => {
    const {
        to,
        type,
        onClick,
        variant,
        children,
        fullWidth,
        borderStyle,
        ...rest
    } = props

    const isFullWidth = Boolean(fullWidth)

    return to ? (
        <Link to={to} style={{ textDecoration: "none" }}>
            <Wrapper
                type={type}
                variant={variant}
                onClick={onClick}
                fullWidth={isFullWidth}
                borderStyle={borderStyle}
                {...rest}
            >
                {children}
            </Wrapper>
        </Link>
    ) : (
        <Wrapper
            type={type}
            variant={variant}
            onClick={onClick}
            fullWidth={isFullWidth}
            borderStyle={borderStyle}
            {...rest}
        >
            {children}
        </Wrapper>
    )
}

Button.defaultProps = {
    type: "button",
    size: "medium",
}

Button.propTypes = {
    props: PropTypes.shape({
        to: PropTypes.string,
        fullWidth: PropTypes.bool,
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
            .isRequired,
        type: PropTypes.oneOf(["button", "submit", "reset"]),
        size: PropTypes.oneOf(["large", "medium", "small"]).isRequired,
        variant: PropTypes.oneOf(["outlined", "contained", "filled"])
            .isRequired,
        borderStyle: PropTypes.oneOf(["square", "rounded"]).isRequired,
        onClick: PropTypes.func,
    }),
}

export default Button
