import React from "react"
import Img from "gatsby-image"
import Zoom from "react-reveal/Zoom"
import Slide from "react-reveal/Slide"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"

import { get } from "../../helpers/utils"
import Text from "../../components/shared/Text"
import Button from "../../components/shared/Button"
import { ROUTES, colors } from "../../helpers/variables"
import ImgContainer from "../../components/shared/ImgContainer"
import {
    Section,
    ImgsWrapper,
    BottomImgWrapper,
    TopImgWrapper,
    DarkBlock,
    DarkBlockWrapper,
    YellowBlock,
    YellowBlockWrapper,
    GrayBlockWrapper,
    GrayBlock,
    LineWrapper,
    Line,
    TextWrapper,
} from "./styled"

const HeaderSection = () => {
    const data = useStaticQuery(graphql`
        query {
            bottomImg: file(
                relativePath: { eq: "assets/images/headerBottomImg.jpg" }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 395, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            topImg: file(
                relativePath: { eq: "assets/images/headerTopImg.jpg" }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 503, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const fluidTop = get(data, "topImg.childImageSharp.fluid", {})
    const fluidBottom = get(data, "bottomImg.childImageSharp.fluid", {})

    return (
        <>
            <ImgContainer>
                <ImgsWrapper>
                    <TopImgWrapper>
                        <Zoom>
                            <Img fluid={fluidTop} alt="Квартира" />
                        </Zoom>
                    </TopImgWrapper>
                    <BottomImgWrapper>
                        <Zoom>
                            <Img
                                fluid={fluidBottom}
                                alt="Два бокала с шампанским на столе."
                            />
                        </Zoom>
                    </BottomImgWrapper>
                    <YellowBlockWrapper>
                        <Slide right>
                            <YellowBlock />
                        </Slide>
                    </YellowBlockWrapper>
                    <DarkBlockWrapper>
                        <Slide right>
                            <DarkBlock />
                        </Slide>
                    </DarkBlockWrapper>
                    <GrayBlockWrapper>
                        <Slide bottom>
                            <GrayBlock />
                        </Slide>
                    </GrayBlockWrapper>
                </ImgsWrapper>
            </ImgContainer>

            <div className="container">
                <Section>
                    <Fade>
                        <TextWrapper>
                            <Text variant="h1" color={colors.green}>
                                СТУДИЯ ДИЗАЙНА
                            </Text>

                            <Text
                                variant="p"
                                color={colors.green}
                                style={{ marginBottom: 50, marginTop: 15 }}
                            >
                                От разработки проекта - до его воплощения
                            </Text>

                            <Button
                                borderStyle="rounded"
                                variant="contained"
                                to={ROUTES.WORKS}
                                size="large"
                            >
                                ПРОЕКТЫ
                            </Button>
                        </TextWrapper>
                    </Fade>

                    <LineWrapper>
                        <Slide top>
                        </Slide>
                    </LineWrapper>
                </Section>
            </div>
        </>
    )
}

export default HeaderSection
