import styled from "styled-components"

import { media } from "../../helpers/variables"

export const Section = styled.section`
    position: relative;
    padding: 160px 0 80px 0;

    ${media.md} {
        padding: 140px 0 240px 0;
    }
    ${media.lg} {
        padding: 140px 0 170px 0;
    }
    ${media.xl} {
        padding: 280px 0 340px 0;
    }
`

export const TextWrapper = styled.div`
    width: 100%;

    p {
        width: 100%;
    }

    ${media.md} {
        max-width: 355px;

        p {
            max-width: 250px;
        }
    }

    @media (max-width: 767px) {
        display: flex;
        align-items: center;
        flex-direction: column;

        h1,
        p {
            text-align: center !important;
        }
    }
`

export const ImgsWrapper = styled.div`
    position: relative;
    height: 430px;
    width: 100%;
    top: 106px;

    max-width: 700px;
    margin: 0 auto;

    @media (max-width: 420px) {
        height: 320px;
    }

    ${media.md} {
        max-width: initial;
        margin: initial;
        top: initial;

        position: absolute;
        padding-top: 106px;
        padding-top: 0;
        height: 430px;
        width: 440px;
        top: 140px;
        right: 0;
    }
    ${media.xl} {
        height: 860px;
        width: 885px;
        top: 105px;
        right: 0;
    }
`

export const TopImgWrapper = styled.div`
    position: absolute;
    display: block;
    right: -15px;
    z-index: 1;
    top: -20px;

    max-width: 300px;
    height: auto;
    width: 100%;

    @media (max-width: 420px) {
        max-width: 227px;
    }

    ${media.md} {
        max-width: initial;
        height: initial;
        width: 300px;
        right: 10px;
    }
    ${media.xl} {
        width: 503px;
        right: 70px;
        top: 0px;
    }
`

export const BottomImgWrapper = styled.div`
    position: absolute;
    display: block;
    bottom: 15px;
    z-index: 1;
    width: 60%;
    left: 0px;

    ${media.md} {
        width: 300px;
        bottom: 0px;
    }
    ${media.xl} {
        width: 395px;
        bottom: 50px;
        left: 50px;
    }
`

export const YellowBlockWrapper = styled.div`
    display: none;

    ${media.md} {
        position: absolute;
        display: block;
        height: 280px;
        width: 200px;
        z-index: -1;
        bottom: 0;
        left: 0;
    }
    ${media.xl} {
        width: 700px;
        height: 560px;
    }

    div {
        height: 100%;
        width: 100%;
    }
`

export const YellowBlock = styled.div`
    background-color: hsla(70, 25%, 55%, 0.2);
`

export const DarkBlockWrapper = styled.div`
    position: absolute;
    display: block;
    height: 90%;
    z-index: -2;
    width: 70%;
    right: 5%;
    bottom: 0;

    ${media.md} {
        top: 0;
        width: 120%;
        height: 100%;
        right: -260px;
        bottom: initial;
    }

    div {
        height: 100%;
        width: 100%;
    }
`

export const DarkBlock = styled.div`
    background-color: hsla(213, 8%, 46%, 1);
`

export const GrayBlockWrapper = styled.div`
    position: absolute;
    display: block;
    height: 310px;
    bottom: 20px;
    right: 10px;
    z-index: -3;
    width: 90%;

    @media (max-width: 420px) {
        height: 220px;
    }

    ${media.md} {
        top: 35px;
        width: 100%;
        right: -50px;
        height: 350px;
        bottom: initial;
    }
    ${media.xl} {
        height: 700px;
    }

    div {
        height: 100%;
        width: 100%;
    }
`

export const GrayBlock = styled.div`
    background-color: hsla(210, 7%, 62%, 1);
`

export const LineWrapper = styled.div`
    ${media.md} {
        overflow: hidden;
        position: absolute;
        left: 0;
        width: 2px;
        bottom: 50px;
        height: 70px;
        display: block;
    }
    ${media.xl} {
        height: 140px;
    }
`

export const Line = styled.div`
    ${media.md} {
        width: 2px;
        height: 70px;
        display: block;
        background-color: hsla(210, 12%, 29%, 1);
    }
    ${media.xl} {
        height: 140px;
    }
`
