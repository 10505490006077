import React from "react"
import Img from "gatsby-image"
import Zoom from "react-reveal/Zoom"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"

import { get } from "../../helpers/utils"
import { ROUTES } from "../../helpers/variables"
import Text from "../../components/shared/Text"
import Link from "../../components/shared/Link"
import ImgContainer from "../../components/shared/ImgContainer"
import {
    Section,
    Description,
    ImgsWrapper,
    GrayBlock,
    TopImgWrapper,
    BottomImgWrapper,
    GrayBlockWrapper,
} from "./styled"

const ConceptualProjects = () => {
    const data = useStaticQuery(graphql`
        query {
            topImg: file(
                relativePath: {
                    eq: "assets/images/conceptualProjectsTopImg.jpg"
                }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 535, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            bottomImg: file(
                relativePath: {
                    eq: "assets/images/conceptualProjectsBottomImg.jpg"
                }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const fluidTop = get(data, "topImg.childImageSharp.fluid", {})
    const fluidBottom = get(data, "bottomImg.childImageSharp.fluid", {})
    
    // вставляем пробелы после запятой 
    //const descriptionElement = document.querySelector("Description")
    //const textElements = descriptionElement.querySelectorAll("Text")
    
    //textElements.forEach(element => {const originalText = element.innerHTML
        //const modifiedText = originalText.replace(/,/g, ", ")
       // element.innerHTML = modifiedText})
     // конец процедуры по пробелам после запятой

    return (
        <>
            <ImgContainer>
                <ImgsWrapper>
                    <BottomImgWrapper>
                        <Zoom>
                            <Img
                                fluid={fluidBottom}
                                alt="Темная гостинная с узкой полоской света."
                            />
                        </Zoom>
                    </BottomImgWrapper>

                    <TopImgWrapper>
                        <Zoom>
                            <Img
                                fluid={fluidTop}
                                alt="Модель футуристического дома."
                            />
                        </Zoom>
                    </TopImgWrapper>

                    <GrayBlockWrapper>
                        <Fade>
                            <GrayBlock />
                        </Fade>
                    </GrayBlockWrapper>
                </ImgsWrapper>
            </ImgContainer>

            <div className="container">
                <Section>
                    <Fade bottom>
                        <div>
                            <Text variant="h3">
                                КОНЦЕПТУАЛЬНЫЕ
                                <br /> ПРОЕКТЫ
                            </Text>

                            <Description>
                                <Text variant="h6">
                                    Представляю работы своих студентов, будущих архитекторов и дизайнеров.<br />
                                    Каждый проект - это результат творчества, неожиданный, небанальный, непривычный взгляд
                                    на решение поставленной задачи.
                                    <br />
                                    <br />
                                    Руководитель проектов: ктн, доцент каф. РРАНиОА
                                    <br />
                                    ИАиД КГАСУ Зилянева О.Е.
                                </Text>

                                <Link to={ROUTES.CONCEPT} variant="moreButton">
                                    УЗНАТЬ БОЛЬШЕ
                                </Link>
                            </Description>
                        </div>
                    </Fade>
                </Section>
            </div>
        </>
    )
}

export default ConceptualProjects
