import React from "react"

import SEO from "../components/shared/Seo"
import HeaderSection from "../sections/Header"
import Layout from "../components/shared/Layout"
import ArchitecturalProjects from "../sections/ArchitecturalProjects"
import InteriorDesign from "../sections/InteriorDesign"
import ConceptualProjects from "../sections/ConceptualProjects"
import AboutSection from "../sections/About"

const IndexPage = () => {
    return (
        <Layout>
            <SEO title="Главная страница" />

            <HeaderSection />
            <ArchitecturalProjects />
            <InteriorDesign />
            <ConceptualProjects />
            <AboutSection />
        </Layout>
    )
}

export default IndexPage
