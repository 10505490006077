import React from "react"
import PropTypes from "prop-types"

const ImgContainer = ({ children }) => (
    <div className="container-fluid" style={{ maxWidth: 1800 }}>
        <div style={{ position: "relative" }}>{children}</div>
    </div>
)

ImgContainer.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ImgContainer
