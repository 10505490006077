import styled from "styled-components"

import { media } from "../../helpers/variables"

export const Section = styled.section`
    position: relative;
    padding: 0px 0 80px 0;

    ${media.md} {
        padding: 0px 0 130px 0;
    }
    ${media.lg} {
        padding: 40px 0 260px 0;
    }

    ::after {
        content: "";
        clear: both;
        display: table;
    }
`

export const Description = styled.div`
    z-index: 1;
    width: 100%;
    max-width: 400px;
    position: relative;

    h6 {
        line-height: 26px;
        padding: 30px 0 45px 0;

        ${media.md} {
            padding: 30px 0 90px 0;
        }
    }
`

export const Left = styled.div`
    ${media.md} {
        float: left;
        width: 50%;
        position: relative;
    }
`

export const Right = styled.div`
    padding-top: 50px;

    ${media.md} {
        float: right;
        width: 50%;
    }
`

export const PhotoImgWrapper = styled.div`
    ${media.md} {
        position: absolute;
        width: 377px;
        left: -80px;
        top: 0;
    }
    ${media.lg} {
        left: 0;
    }
`

export const SignatureImgWrapper = styled.div`
    position: absolute;
    width: 50%;
    z-index: 2;
    right: 0px;
    top: -20px;

    ${media.md} {
        width: 268px;
        right: 70px;
        top: -60px;
    }
`

export const GrayBlockWrapper = styled.div`
    ${media.lg} {
        position: absolute;
        height: 590px;
        width: 510px;
        left: -133px;
        z-index: -1;
        top: -60px;
    }

    div {
        height: 100%;
        width: 100%;
    }
`

export const GrayBlock = styled.div`
    background-color: hsla(207, 6%, 70%, 1);
`

export const YellowBlockWrapper = styled.div`
    ${media.lg} {
        position: absolute;
        height: 590px;
        right: 125px;
        width: 430px;
        z-index: -2;
        top: 0;
    }

    div {
        height: 100%;
        width: 100%;
    }
`

export const YellowBlock = styled.div`
    background-color: hsla(96, 5%, 80%, 1);
`
