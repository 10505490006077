import styled from "styled-components"

import { media } from "../../helpers/variables"

export const Section = styled.section`
    position: relative;
    padding: 80px 0;

    @media (max-width: 620px) {
        padding-top: 60px;
    }

    ${media.lg} {
        padding: 180px 0;
    }
`

export const Description = styled.div`
    width: 100%;
    max-width: 400px;

    h6 {
        line-height: 26px;
        padding: 30px 0 45px 0;

        ${media.md} {
            padding: 30px 0 90px 0;
        }
    }
`

export const ImgsWrapper = styled.div`
    position: relative;
    max-width: 650px;
    margin: 0 auto;
    height: 440px;
    width: 100%;
    right: 0;
    top: 0;

    @media (max-width: 620px) {
        height: 320px;
    }
    @media (max-width: 420px) {
        height: 240px;
    }

    ${media.lg} {
        position: absolute !important;
        max-width: initial;
        margin: initial;
        width: 50%;
        top: 190px;
    }
    ${media.xl} {
        height: 595px;
        width: 810px;
        top: 175px;
        right: 0;
    }
`

export const TopImgWrapper = styled.div`
    position: absolute;
    display: block;
    width: 60%;
    right: 0px;
    top: 30px;

    ${media.lg} {
        width: 300px;
    }
    ${media.xl} {
        width: 395px;
        right: 110px;
    }
`

export const BottomImgWrapper = styled.div`
    position: absolute;
    width: 60%;
    bottom: 0;
    left: 0;

    ${media.lg} {
        left: initial;
        width: 350px;
    }
    ${media.xl} {
        right: initial;
        width: 454px;
        bottom: 0;
        left: 0;
    }
`

export const GrayBlockWrapper = styled.div`
    position: absolute;
    display: block;
    height: 110%;
    z-index: -1;
    right: -260px;
    width: 120%;
    top: 0;

    @media (max-width: 640px) {
        right: -40px;
        width: 90%;
    }

    ${media.xl} {
        width: 110%;
    }

    div {
        width: 100%;
        height: 100%;
    }
`

export const GrayBlock = styled.div`
    background-color: hsla(210, 1%, 73%, 1);
`
