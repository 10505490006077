import React from "react"
import Img from "gatsby-image"
import Zoom from "react-reveal/Zoom"
import Fade from "react-reveal/Fade"
import Slide from "react-reveal/Slide"
import { useStaticQuery, graphql } from "gatsby"

import { get } from "../../helpers/utils"
import Text from "../../components/shared/Text"
import Link from "../../components/shared/Link"
import { ROUTES } from "../../helpers/variables"
import ImgContainer from "../../components/shared/ImgContainer"
import {
    Desktop,
    TopImgWrapper,
    Section,
    BottomImgWrapper,
    GrayBlock,
    GrayBlockWrapper,
    Description,
    ImgsWrapper,
    DividerTopImg,
    DividerBlock,
    DividerBottomImg,
    DividerBlockWrapper,
} from "./styled"

const InteriorDesign = () => {
    const data = useStaticQuery(graphql`
        query {
            dividerTopImg: file(
                relativePath: {
                    eq: "assets/images/interiorDesignDividerTopImg.jpg"
                }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 943, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            dividerBottomImg: file(
                relativePath: {
                    eq: "assets/images/interiorDesignDividerBottomImg.jpg"
                }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1705, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            topImg: file(
                relativePath: { eq: "assets/images/interiorDesignTopImg.jpg" }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            bottomImg: file(
                relativePath: {
                    eq: "assets/images/interiorDesignBottomImg.jpg"
                }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 362) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const fluidTop = get(data, "topImg.childImageSharp.fluid", {})
    const fluidBottom = get(data, "bottomImg.childImageSharp.fluid", {})
    const fluidDividerTop = get(data, "dividerTopImg.childImageSharp.fluid", {})
    const fluidDividerBottom = get(
        data,
        "dividerBottomImg.childImageSharp.fluid",
        {}
    )

    return (
        <>
            <Desktop>
                <div className={"container"}>
                    <Zoom>
                        <DividerTopImg
                            fluid={fluidDividerTop}
                            alt="Современный дачный дом."
                        />
                    </Zoom>
                </div>

                <DividerBlockWrapper>
                    <Slide left>
                        <DividerBlock />
                    </Slide>
                </DividerBlockWrapper>
            </Desktop>

            <ImgContainer>
                <ImgsWrapper>
                    <TopImgWrapper>
                        <Zoom>
                            <Img
                                fluid={fluidTop}
                                alt="Светлая кухня в многоквартирном дома."
                            />
                        </Zoom>
                    </TopImgWrapper>
                    <BottomImgWrapper>
                        <Zoom>
                            <Img fluid={fluidBottom} alt="Детская комната." />
                        </Zoom>
                    </BottomImgWrapper>
                    <GrayBlockWrapper>
                        <Slide bottom>
                            <GrayBlock />
                        </Slide>
                    </GrayBlockWrapper>
                </ImgsWrapper>

                <div className="container">
                    <Section>
                        <Fade bottom>
                            <div>
                                <Text
                                    variant="h3"
                                    style={{ zIndex: 1, position: "relative" }}
                                >
                                    ДИЗАЙН
                                    <br /> ИНТЕРЬЕРОВ
                                </Text>
                                <Description>
                                    <Text variant="h6">
                                        В основу создания любого интерьера
                                        всегда закладывается поиск
                                        композиционного построения пространства.
                                        И только на заключительном этапе
                                        проектрирования это пространство
                                        наполняется деталями, цветом, фактурой и
                                        превращается в интерьер.
                                        <br />
                                        <br />
                                        Красивая, гармоничная среда, безусловно,
                                        добавляет человку счастья, но она не
                                        создает его - счастьe каждый ищет в
                                        себе.
                                    </Text>

                                    <Link
                                        to={ROUTES.INTER}
                                        variant="moreButton"
                                    >
                                        УЗНАТЬ БОЛЬШЕ
                                    </Link>
                                </Description>
                            </div>
                        </Fade>
                    </Section>
                </div>
            </ImgContainer>

            <div style={{ position: "relative" }}>
                <Slide left>
                    <DividerBottomImg
                        fluid={fluidDividerBottom}
                        alt="Веранда на дача."
                    />
                </Slide>
            </div>
        </>
    )
}

export default InteriorDesign
