import styled from "styled-components"
import Img from "gatsby-image"

import { media } from "../../helpers/variables"

export const Desktop = styled.div`
    display: none;
    position: relative;

    ${media.lg} {
        display: block;
    }
`

// ===================== DIVIDER TOP IMG =====================>
export const DividerTopImg = styled(Img)`
    width: 100%;

    ${media.lg} {
        position: relative;
        height: auto;
        width: 943px;
        top: 40px;
    }
`

export const DividerBlockWrapper = styled.div`
    display: none;

    ${media.lg} {
        position: absolute;
        display: block;
        height: 150px;
        z-index: -1;
        width: 70%;
        left: 0;
        top: 0;
    }

    div {
        height: 100%;
        width: 100%;
    }
`

export const DividerBlock = styled.div`
    background-color: hsla(245, 7%, 64%, 1);
`
// ===================== ----------- =====================>

export const Section = styled.section`
    position: relative;
    padding: 40px 0 80px 0;

    ${media.lg} {
        padding: 180px 0 160px 0;
    }
`

export const Description = styled.div`
    width: 100%;
    z-index: 1;
    max-width: 400px;
    position: relative;

    h6 {
        line-height: 26px;
        padding: 30px 0 45px 0;

        ${media.md} {
            padding: 30px 0 90px 0;
        }
    }
`

export const ImgsWrapper = styled.div`
    position: relative;
    display: block;
    height: 200px;
    width: 100%;

    ${media.custom(500)} {
        height: 400px;
    }
    ${media.custom(800)} {
        height: 600px;
    }

    ${media.lg} {
        position: absolute;
        height: 320px;
        top: 300px;
        width: 50%;
        right: 0;
    }
    ${media.xl} {
        height: 490px;
        width: 710px;
        top: 200px;
    }
`

export const TopImgWrapper = styled.div`
    position: absolute;
    width: 55%;
    left: 0px;

    ${media.lg} {
        top: -30%;
    }
    ${media.xl} {
        top: 0;
        width: 420px;
    }
`

export const BottomImgWrapper = styled.div`
    position: absolute;
    bottom: 5%;
    width: 55%;
    right: 0;

    ${media.xl} {
        width: 362px;
        bottom: 30px;
        right: 100px;
    }
`

export const GrayBlockWrapper = styled.div`
    position: absolute;
    max-height: 170px;
    display: block;
    height: 100%;
    z-index: -1;
    right: -35%;
    width: 125%;
    bottom: 0;

    ${media.custom(400)} {
        max-height: 180px;
    }
    ${media.custom(500)} {
        max-height: 300px;
    }
    ${media.custom(660)} {
        max-height: 340px;
    }
    ${media.md} {
        max-height: 460px;
    }
    ${media.lg} {
        max-height: 370px;
    }

    div {
        height: 100%;
        width: 100%;
    }
`

export const GrayBlock = styled.div`
    background-color: hsla(0, 0%, 80%, 1);
`

// ================= DIVIDER BOTTOM IMG ==================>
export const DividerBottomImg = styled(Img)`
    display: none;

    ${media.lg} {
        max-width: 1705px;
        margin: 0 auto;
        display: block;
        height: auto;
        width: 100%;
    }
`
// ===================== ----------- ====================>
