import styled from "styled-components"

import { media } from "../../helpers/variables"

export const Section = styled.section`
    position: relative;
    padding: 40px 0 80px 0;

    ${media.lg} {
        padding: 240px 0 160px 0;
    }
`

export const Description = styled.div`
    width: 100%;
    max-width: 500px;

    h6 {
        line-height: 26px;
        padding: 30px 0 45px 0;

        ${media.md} {
            padding: 30px 0 90px 0;
        }
    }
`

export const ImgsWrapper = styled.div`
    position: relative;
    height: 200px;
    width: 100%;

    ${media.custom(400)} {
        height: 250px;
    }
    ${media.custom(500)} {
        height: 300px;
    }
    ${media.custom(600)} {
        height: 340px;
    }
    ${media.custom(700)} {
        height: 380px;
    }
    ${media.custom(800)} {
        height: 480px;
    }
    ${media.custom(900)} {
        height: 580px;
    }

    ${media.lg} {
        position: absolute;
        height: 500px;
        width: 50%;
        top: 200px;
        right: 0;
    }
    ${media.xl} {
        height: 580px;
        width: 685px;
        top: 200px;
    }
    ${media.custom(1500)} {
        right: 100px;
    }
`

export const TopImgWrapper = styled.div`
    position: absolute;
    width: 65%;
    left: 0;
    top: 0;

    ${media.lg} {
        width: 450px;
    }
    ${media.xl} {
        width: 535px;
    }
`

export const BottomImgWrapper = styled.div`
    position: absolute;
    width: 65%;
    bottom: 0;
    right: 0;

    ${media.lg} {
        width: 400px;
    }
    ${media.xl} {
        width: 520px;
    }
`

export const GrayBlockWrapper = styled.div`
    position: absolute;
    width: 70%;
    margin-left: -15%;
    margin-top: -15%;
    z-index: -1;
    right: 0;
    height: 70%;
    left: 25%;
    top: 40%;

    ${media.custom(600)} {
        top: 40%;
        height: 80%;
    }
    ${media.custom(800)} {
        height: 440px;
        width: 460px;
        left: 50%;
        top: 50%;
        margin-left: -230px;
        margin-top: -220px;
    }

    div {
        height: 100%;
        width: 100%;
    }
`

export const GrayBlock = styled.div`
    background-color: hsla(211, 20%, 77%, 1);
`
