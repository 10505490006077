import styled from "styled-components"

import { PureButton } from "../styled"
import { colors, transitions, media } from "../../../helpers/variables"

export const Wrapper = styled(PureButton)`
    position: relative;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    transition: ${transitions.main};
    border-radius: ${props =>
        props.borderStyle === "square" ? "0px" : "95px"};
    color: ${props => (props.variant === "outlined" ? colors.green : "white")};
    background-color: ${props => {
        if (props.variant === "outlined") return "transparent"
        else return colors.green
    }};
    border: ${props =>
        props.variant === "outlined"
            ? "1px solid transparent"
            : `1px solid ${colors.green}`};
    padding: ${props => {
        if (props.size === "large") return "20px 65px"
        else if (props.size === "medium") return "15px 30px"
        else return "10px"
    }};
    font-size: ${props => {
        if (props.size === "large") return "18px"
        else if (props.size === "medium") return "16px"
        else return "14px"
    }};
    letter-spacing: ${props => {
        if (props.size === "large") return "1.5px"
        else return "0px"
    }};

    ${props => props.fullWidth && `width: 100%;`};

    ${media.md} {
        font-size: ${props => {
            if (props.size === "large") return "20px"
            else if (props.size === "medium") return "18px"
            else return "16px"
        }};

        :hover {
            border-color: ${colors.green};
            background-color: transparent;
            color: ${props =>
                props.variant === "outlined" || props.variant === "contained"
                    ? colors.green
                    : "white"};
            background-color: ${props => {
                if (props.variant === "filled") return `hsla(193, 13%, 20%, 1)`
                if (props.variant === "contained") return "transparent"
            }};
        }
    }

    :disabled {
        opacity: 0.5;
    }
`
