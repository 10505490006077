import React from "react"
import Img from "gatsby-image"
import Zoom from "react-reveal/Zoom"
import Slide from "react-reveal/Slide"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"

import { get } from "../../helpers/utils"
import Link from "../../components/shared/Link"
import { ROUTES } from "../../helpers/variables"
import Text from "../../components/shared/Text"
import ImgContainer from "../../components/shared/ImgContainer"
import {
    Section,
    Description,
    ImgsWrapper,
    TopImgWrapper,
    BottomImgWrapper,
    GrayBlock,
    GrayBlockWrapper,
} from "./styled"

const ArchitecturalProjects = () => {
    const data = useStaticQuery(graphql`
        query {
            bottomImg: file(
                relativePath: { eq: "assets/images/archBottomImg.jpg" }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            topImg: file(
                relativePath: { eq: "assets/images/archTopImg.jpg" }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 395, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const fluidTop = get(data, "topImg.childImageSharp.fluid", {})
    const fluidBottom = get(data, "bottomImg.childImageSharp.fluid", {})

    return (
        <>
            <ImgContainer>
                <ImgsWrapper>
                    <TopImgWrapper>
                        <Zoom>
                            <Img
                                fluid={fluidTop}
                                alt="Беседка в центре небольшого пруда."
                            />
                        </Zoom>
                    </TopImgWrapper>
                    <BottomImgWrapper>
                        <Zoom>
                            <Img
                                fluid={fluidBottom}
                                alt="Каменный двухэтажный дом с просторным балконом, черепичной крышой и красивыми коллонами."
                            />
                        </Zoom>
                    </BottomImgWrapper>
                    <GrayBlockWrapper>
                        <Slide right>
                            <GrayBlock />
                        </Slide>
                    </GrayBlockWrapper>
                </ImgsWrapper>
            </ImgContainer>

            <div className="container">
                <Section>
                    <Fade bottom>
                        <div>
                            <Text variant="h3">
                                АРХИТЕКТУРНЫЕ
                                <br /> ПРОЕКТЫ
                            </Text>
                            <Description>
                                <Text variant="h6">
                                    Разработка архитектурного проекта
                                    индивидуального жилого дома подразумевает
                                    несколько этапов:
                                    <br />
                                    - эскизный проект дома
                                    <br />
                                    - проект по благоустройству территории
                                    <br />
                                    - проекты малых архитектурных форм (МАФ)
                                    <br />
                                    <br />
                                    Будет ли это классицизм, модерн, барокко или
                                    этника - всё это дело вкуса. Прежде всего,
                                    дом начинается с истории - истории семьи.
                                </Text>
                            </Description>

                            <Link variant="moreButton" to={ROUTES.ARCH}>
                                УЗНАТЬ БОЛЬШЕ
                            </Link>
                        </div>
                    </Fade>
                </Section>
            </div>
        </>
    )
}

export default ArchitecturalProjects
