import React from "react"
import Img from "gatsby-image"
import Zoom from "react-reveal/Zoom"
import Fade from "react-reveal/Fade"
import Slide from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"

import { get } from "../../helpers/utils"
import Text from "../../components/shared/Text"
import Link from "../../components/shared/Link"
import { ROUTES } from "../../helpers/variables"
import {
    Section,
    Description,
    Left,
    Right,
    PhotoImgWrapper,
    SignatureImgWrapper,
    GrayBlock,
    YellowBlock,
    GrayBlockWrapper,
    YellowBlockWrapper,
} from "./styled"

const About = () => {
    const data = useStaticQuery(graphql`
        query {
            photo: file(
                relativePath: { eq: "assets/images/photo.jpg" }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 377, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            signature: file(
                relativePath: { eq: "assets/images/signature.png" }
                sourceInstanceName: { eq: "src" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 268) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const fluidPhoto = get(data, "photo.childImageSharp.fluid", {})
    const fluidSignature = get(data, "signature.childImageSharp.fluid", {})

    return (
        <div className="container">
            <Section>
                <Left>
                    <SignatureImgWrapper>
                        <Zoom>
                            <Img
                                fluid={fluidSignature}
                                alt="Подпись архитектора"
                            />
                        </Zoom>
                    </SignatureImgWrapper>
                    <PhotoImgWrapper>
                        <Zoom>
                            <Img
                                fluid={fluidPhoto}
                                alt="Фото Ольги Зиляневой. Сидит на стуле, облокотившись на спинку локтями и пристально смотрит вперед."
                            />
                        </Zoom>
                    </PhotoImgWrapper>
                    <GrayBlockWrapper>
                        <Slide left>
                            <GrayBlock />
                        </Slide>
                    </GrayBlockWrapper>
                    <YellowBlockWrapper>
                        <Fade>
                            <YellowBlock />
                        </Fade>
                    </YellowBlockWrapper>
                </Left>

                <Right>
                    <Fade bottom>
                        <div>
                            <Text
                                variant="h3"
                                style={{ zIndex: 1, position: "relative" }}
                            >
                                О СТУДИИ
                            </Text>
                            <Description>
                                <Text variant="h6">
                                    На мой взгляд, задача профессионального
                                    дизайнера заключается в том, чтобы выявить
                                    желания и мечты заказчика, оформить их и
                                    реализовать, оставаясь при этом абсолютно
                                    объективным. Объективным до самоустранения.
                                    <br />
                                    <br />
                                    Стиль - это сам человек, выражение его
                                    индивидуальности.
                                </Text>

                                <Link to={ROUTES.CONTACTS} variant="moreButton">
                                    УЗНАТЬ БОЛЬШЕ
                                </Link>
                            </Description>
                        </div>
                    </Fade>
                </Right>
            </Section>
        </div>
    )
}

export default About
